//BRAND
export const name = 'Anything Academy';
export const company = 'Anything Academy';
export const websiteURL = 'https://anything.academy';
export const serverURL = 'https://production-lvvc.onrender.com';
export const logo = 'https://firebasestorage.googleapis.com/v0/b/anythingacademy-61bb4.appspot.com/o/logo.png?alt=media&token=bbc9852e-2c1a-486c-b940-d72bd7069f4e';
export const razorpayEnabled = false;
export const paypalEnabled = false;
export const stripeEnabled = true;
export const paystackEnabled = false;
export const flutterwaveEnabled = false;

//PRICING :-

//FREE 
export const FreeType = 'Free Plan';
export const FreeCost = 0;
export const FreeTime = '';

//MONTHLY 
export const MonthType = 'Monthly Plan';
export const MonthCost = 9;
export const MonthTime = 'month';

//YEARLY 
export const YearType = 'Yearly Plan';
export const YearCost = 99;
export const YearTime = 'year';

//TESTIMONIAL
export const review = "Anything Academy made creating content way easier! Now, I can come up with ideas fast and save so much time. Whether you’re teaching, learning, or just sharing what you know, this tool really helps keep things simple and effective.";
export const from = "Marcela P.";
export const profession = 'Educator';
export const photoURL = 'https://firebasestorage.googleapis.com/v0/b/anythingacademy-61bb4.appspot.com/o/v3_0163346.png?alt=media&token=c07e7ed2-f02e-4e7e-9b40-3c064f335f61';

//PAYPAL
export const paypalPlanIdOne = "";
export const paypalPlanIdTwo = "";

//RAZORPAY
export const razorpayKeyId = "";
export const razorpayPlanIdOne = "";
export const razorpayPlanIdTwo = "";

//STRIPE
export const stripePlanIdOne = "price_1QFKojF1FzQNVimEi16uV67y";
export const stripePlanIdTwo = "price_1QFKphF1FzQNVimEWwTVnYZq";

//PAYSTACK
export const paystackPlanIdOne = "";
export const paystackPlanIdTwo = "";
export const amountInZarOne = '';
export const amountInZarTwo = '';

//FLUTTERWAVE
export const flutterwavePlanIdOne = "67960";
export const flutterwavePlanIdTwo = "67961";
export const flutterwavePublicKey = "FLWPUBK_TEST-6ee1faf6460ea587f510a024ac4c2b23-X";

//SOCIAL SIGNIN
export const googleClientId = "743905512638-6oi0gb58kspn1fmab914g40b3jnvrhu9.apps.googleusercontent.com";
export const facebookClientId = "482063931505448";